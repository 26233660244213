<template>
  <b-container fluid>
    <b-modal hide-footer title="Consulta de la liquidación" id="modalLiquidacionAjuste" body-bg-variant="dark" header-bg-variant="dark" footer-bg-variant="dark"  size="lg">
      <template #modal-header="{ close }">
        <div class="d-flex flex-row-reverse" style="width: 100%">
          <div @click="close()" style="border-radius: 5px;background: #DF122E; padding: .25rem; font-size: 10px; cursor: pointer">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </template>
      <NominaAjustePersona v-if="dataParaLiquidacionAjuste" :liquidacionId="dataParaLiquidacionAjuste.liquidacionId" :liquidacionPersonaId="dataParaLiquidacionAjuste.liquidacionPersonaId">

      </NominaAjustePersona>
    </b-modal>

    <b-modal id="consultaUnicaModal" body-bg-variant="dark" header-bg-variant="dark" footer-bg-variant="dark" size="lg">
      <template #modal-header="{ close }">
        <div class="d-flex justify-content-between" style="width: 100%">
          <h3>Consulta de la liquidación</h3>
          <!-- Se debe consultar la informacion en aportes no solo en la DB -->
          
          <div @click="close()" style="border-radius: 5px; background: #DF122E; padding: .25rem; font-size: 10px; cursor: pointer; max-height: 18px">
            <i class="fas fa-times"></i>
          </div>
        
        </div>
      </template>
      
      <NominaConsultaEstados v-if="dataConsultaLiquidacionTrabajador"  :liquidacionPersonaId="dataConsultaLiquidacionTrabajador.liquidacionPersonaId" />
      <template #modal-footer="{ close }">
        <div class="d-flex flex-row-reverse">
          <vs-button @click="close" success  >Cerrar</vs-button>
        </div>
      </template>
    </b-modal>
    <b-modal title="Agregar empleados" id="my-modal" body-bg-variant="dark" header-bg-variant="dark" footer-bg-variant="dark" size="lg">
      <!-- <h4 class="px-4 py-4"><b>DEVENGADOS</b></h4> -->
      <div class="row px-5">
        <div class="col-md-12">
          <div class="mb-4">
            <p>Selecciona los empleados que deseas incluir en este período de nómina.</p>

            <div class="row px-5 mb-4 mt-4">
              <div class="table-responsive px-4 mt-3">
                <table id="datatable_empleados2" class="table table-bordered table-responsive-md table-striped text-center">
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Salario</th>
                      <th>Identificación</th>
                      <th>Opciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in listaEmpleados" :key="item.id">
                      <td>{{ item.nombres + " " + item.apellidos }}</td>
                      <td>
                        {{
                          item.salario != null
                            ? item.salario.toLocaleString("es-CO", {
                                style: "currency",
                                currency: "COP",
                              })
                            : 0.0
                        }}
                      </td>
                      <td>{{ item.numeroDocumento }}</td>
                      <td>
                        <span class="pointer">
                          <VueToggles height="20" width="50" :value="item.show" @click="agregarEmpleado(item)" />
                          <!-- <i @click="$router.push('departamentos_editar/' + item.id)" class="fas fa-solid fa-pen px-1 text-success"></i>
                          <i @click="deleteRow(item.id)" class="fas fa-solid fa-trash px-1 text-danger"></i> -->
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100 text-right">
          <!-- <button type="submit" class="mx-1 btn px-4 py-2 btn-primary">Agregar</button> -->
          <button class="mx-1 btn px-4 py-2 btn-secondary" @click="$bvModal.hide('my-modal')">Cerrar</button>
        </div>
      </template>
    </b-modal>
    <b-modal title="Nuevo Devengado" id="my-modalDevengados" body-bg-variant="dark" header-bg-variant="dark" footer-bg-variant="dark" size="md" hide-footer>
      <form @submit.prevent="agregarNuevoItem">
        <div class="row px-5">
          <div class="col-md-12">
            <div class="mb-4">
              <!-- {{ this.listaDevengados }} -->
              <!-- <pre>{{ JSON.stringify(listaDevengados, null, 2) }}</pre> -->
              <!-- {{usuarioSeleccionado}} -->
              <label for=" " class="form-label mt-3">Tipo de devengado</label>
              <b-form-select
                required
                @change="selecionarItem($event, 'devengado')"
                value-field="listaDevengadoId"
                text-field="listaDevengado.descripcion"
                :options="listaDevengados"
              ></b-form-select>

              <div v-if="['Cesantías', 'Prima salarial', 'Vacaciones disfrutadas', 'Vacaciones compensadas no disfrutadas'].includes(nuevoItem.descripcion)">
                <div class="mb-3 mt-3">
                  <label for="exampleFormControlInput1" class="form-label"> Días </label>
                  <input
                    v-on:keyup="calculoNuevoItem"
                    required
                    onfocus="this.select()"
                    v-model="nuevoItem.dias"
                    type="number"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                  />
                </div>
                <div class="mb-3 mt-3">
                  <label for="exampleFormControlInput1" class="form-label"> Valor </label>
                  <input
                    required
                    v-on:keyup="calculoNuevoItem"
                    onfocus="this.select()"
                    v-model="nuevoItem.valor"
                    type="number"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                  />
                </div>
              </div>
              <div
                v-if="
                  [
                    'Prima no salarial',
                    'Bonificación salarial',
                    'Bonificación no salarial',
                    'Auxilio salarial',
                    'Auxilio no salarial',
                    'Huelga legal',
                    'Otro concepto salarial',
                    'Otro concepto no salarial',
                    'Compensación ordinaria',
                    'Compensación extraordinaria',
                    'Bono de alimentación salarial',
                    'Bono de alimentación no salarial',
                    'Otros bonos salariales',
                    'Otros bonos no salariales',
                    'Comisión',
                    'Pago a terceros',
                    'Anticipo',
                    'Dotación',
                    'Apoyo sostenimiento',
                    'Teletrabajo',
                    'Bonificación por retiro',
                    'Indemnización',
                    'Reintegro',
                  ].includes(nuevoItem.descripcion)
                "
              >
                <div class="mb-3 mt-3">
                  <label for="exampleFormControlInput1" class="form-label"> Valor </label>
                  <input
                    required
                    v-on:keyup="calculoNuevoItem"
                    onfocus="this.select()"
                    v-model="nuevoItem.valor"
                    type="number"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                  />
                </div>
              </div>

              <div
                v-if="
                  [
                    'Viáticos salariales',
                    'Viáticos no salariales',
                    'Horas extras diurnas',
                    'Horas extras nocturnas',
                    'Horas extras dominicales y festivas',
                    'Horas extras nocturnas dominicales y festivas',
                    'Horas recargo nocturno',
                    'Horas recargo dominical y festiva',
                    'Hora recargo nocturno dominical y festivos',
                  ].includes(nuevoItem.descripcion)
                "
              >
                <!-- <div class="mb-3 mt-3">
                  <label for="exampleFormControlInput1" class="form-label">Días</label>
                  <input required onfocus="this.select()" v-model="nuevoItem.dias" type="number" class="form-control" id="exampleFormControlInput1" placeholder="" />
                </div> -->
                <div class="mb-3 mt-3">
                  <label
                    v-if="['Viáticos salariales', 'Viáticos no salariales', 'Vacaciones compensadas no disfrutadas'].includes(nuevoItem.descripcion)"
                    for="exampleFormControlInput1"
                    class="form-label"
                  >
                    Valor
                  </label>
                  <label
                    v-else-if="
                      [
                        'Horas extras diurnas',
                        'Horas extras nocturnas',
                        'Horas extras dominicales y festivas',
                        'Horas extras nocturnas dominicales y festivas',
                        'Horas recargo nocturno',
                        'Horas recargo dominical y festiva',
                        'Hora recargo nocturno dominical y festivos',
                      ].includes(nuevoItem.descripcion)
                    "
                    for="exampleFormControlInput1"
                    class="form-label"
                  >
                    Horas
                  </label>
                  <input
                    required
                    v-on:keyup="calculoNuevoItem"
                    onfocus="this.select()"
                    v-model="nuevoItem.valor"
                    type="number"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                  />
                </div>

                <div v-if="['Horas recargo dominical y festiva'].includes(nuevoItem.descripcion)">
                  <div class="form-check form-check-inline">
                    <input
                      @change="
                        porcenta = 0.75;
                        calculoNuevoItem();
                        valporcenta = 1;
                      "
                      class="form-check-input"
                      type="radio"
                      name="flradio1"
                      id="radio1"
                      :checked="valporcenta == 1"
                    />
                    <label class="form-check-label" for="radio1">75 %</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      @change="
                        porcenta = 1.75;
                        calculoNuevoItem();
                        valporcenta = 2;
                      "
                      :checked="valporcenta == 2"
                      class="form-check-input"
                      type="radio"
                      name="flradio1"
                      id="radio2"
                    />
                    <label class="form-check-label" for="radio2">175 %</label>
                  </div>
                </div>

                <div v-if="['Hora recargo nocturno dominical y festivos'].includes(nuevoItem.descripcion)">
                  <div class="form-check form-check-inline">
                    <input
                      @change="
                        porcenta2 = 1.1;
                        calculoNuevoItem();
                        valporcenta2 = 1;
                      "
                      class="form-check-input"
                      type="radio"
                      name="flradio2"
                      id="radio3"
                      :checked="valporcenta2 == 1"
                    />
                    <label class="form-check-label" for="radio3">110 %</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      @change="
                        porcenta2 = 2.1;
                        calculoNuevoItem();
                        valporcenta2 = 2;
                      "
                      class="form-check-input"
                      type="radio"
                      name="flradio1"
                      id="radio4"
                      :checked="valporcenta2 == 2"
                    />
                    <label class="form-check-label" for="radio4">210 %</label>
                  </div>
                </div>
              </div>

              <div
                v-if="
                  [
                    'Incapacidad común',
                    'Incapacidad profesional',
                    'Incapacidad laboral',
                    'Licencia de maternidad o paternidad',
                    'Licencia remunerada',
                    'Licencia no remunerada',
                  ].includes(nuevoItem.descripcion)
                "
              >
                <div class="mb-3 mt-3">
                  <label for="exampleFormControlInput1" class="form-label"> Días </label>
                  <input
                    required
                    v-on:keyup="calculoNuevoItem"
                    onfocus="this.select()"
                    v-model="nuevoItem.dias"
                    type="number"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                  />
                </div>

                <div v-if="['Incapacidad común'].includes(nuevoItem.descripcion)">
                  <div class="form-check form-check-inline">
                    <input
                      @change="
                        porcenta3 = 1;
                        calculoNuevoItem();
                        valporcenta3 = 1;
                      "
                      class="form-check-input"
                      type="radio"
                      name="flradio1"
                      id="radio1"
                      :checked="valporcenta3 == 1"
                    />
                    <label class="form-check-label" for="radio1">100 %</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      @change="
                        porcenta3 = 0.666;
                        calculoNuevoItem();
                        valporcenta3 = 2;
                      "
                      :checked="valporcenta3 == 2"
                      class="form-check-input"
                      type="radio"
                      name="flradio1"
                      id="radio2"
                    />
                    <label class="form-check-label" for="radio2">66.6 %</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      @change="
                        porcenta3 = 0.5;
                        calculoNuevoItem();
                        valporcenta3 = 3;
                      "
                      :checked="valporcenta3 == 3"
                      class="form-check-input"
                      type="radio"
                      name="flradio1"
                      id="radio2"
                    />
                    <label class="form-check-label" for="radio2">50 %</label>
                  </div>
                </div>
              </div>

              <div class="mb-3 mt-3">
                <label for="exampleFormControlInput1" class="form-label"> Costo reflejado en Nómina </label>
                <label class="form-control">$ {{ nuevoItem.total }}</label>
              </div>

              <hr style="border-color: white !important" />
              <div v-for="itemTablet of nominaLiquidacionPersonaDetalle" :key="itemTablet.id">
                <template v-if="itemTablet.tDev > 0">
                  <div class="row">
                    <div class="col-8">
                      {{ itemTablet.concepto }}
                    </div>
                    <div class="col-4 text-right">${{ Number(itemTablet.tDev).toLocaleString("es") }}</div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <!-- <template #modal-footer> -->
        <div class="w-100 text-right">
          <button type="submit" class="mx-1 btn px-4 py-2 btn-primary">Agregar</button>
          <button
            type="button"
            class="mx-1 btn px-4 py-2 btn-secondary"
            @click="
              nuevoItem = {};
              $bvModal.hide('my-modalDevengados');
            "
          >
            Cerrar
          </button>
        </div>
        <!-- </template> -->
      </form>
    </b-modal>
    <b-modal title="Nueva Deducción" id="my-modalDeducciones" body-bg-variant="dark" header-bg-variant="dark" footer-bg-variant="dark" size="md" hide-footer>
      <form @submit.prevent="agregarNuevoItem">
        <div class="row px-5">
          <div class="col-md-12">
            <div class="mb-4">
              <!-- {{ this.listaDevengados }} -->
              <!-- <pre>{{ JSON.stringify(listaDevengados, null, 2) }}</pre> -->
              <!-- {{usuarioSeleccionado}} -->
              <label for=" " class="form-label mt-3">Tipo de deducción</label>
              <b-form-select
                required
                @change="selecionarItem($event, 'deduccion')"
                value-field="listaDeduccionId"
                text-field="listaDeduccion.descripcion"
                :options="listaDeducciones"
              ></b-form-select>

              <div
                v-if="
                  [
                    'Sindicato',
                    'Sanción pública',
                    'Sanción privada',
                    'Libranza',
                    'Pago a terceros',
                    'Anticipos',
                    'Otras deducciones',
                    'Pensión voluntaria',
                    'Retención en la fuente',
                    'AFC',
                    'Cooperativa',
                    'Embargo fiscal',
                    'Plan complementario',
                    'Educación',
                    'Reintegro',
                    'Deuda',
                  ].includes(nuevoItem.descripcion)
                "
              >
                <div class="mb-3 mt-3">
                  <label v-if="['Sindicato'].includes(nuevoItem.descripcion)" for="exampleFormControlInput1" class="form-label"> % </label>
                  <label
                    v-if="
                      [
                        'Sanción pública',
                        'Sanción privada',
                        'Libranza',
                        'Pago a terceros',
                        'Anticipos',
                        'Otras deducciones',
                        'Pensión voluntaria',
                        'Retención en la fuente',
                        'AFC',
                        'Cooperativa',
                        'Embargo fiscal',
                        'Plan complementario',
                        'Educación',
                        'Reintegro',
                        'Deuda',
                      ].includes(nuevoItem.descripcion)
                    "
                    for="exampleFormControlInput1"
                    class="form-label"
                  >
                    Valor
                  </label>
                  <input
                    required
                    v-on:keyup="calculoNuevoItem"
                    onfocus="this.select()"
                    v-model="nuevoItem.valor"
                    type="number"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder=""
                  />
                </div>
              </div>
              <div class="mb-3 mt-3">
                <label for="exampleFormControlInput1" class="form-label"> Costo reflejado en Nómina </label>
                <label class="form-control">$ {{ nuevoItem.total }}</label>
              </div>
              <hr style="border-color: white !important" />
              <div v-for="itemTabletded of nominaLiquidacionPersonaDetalle" :key="itemTabletded.id">
                <template v-if="itemTabletded.tDed > 0">
                  <div class="row">
                    <div class="col-8">
                      {{ itemTabletded.concepto }}
                    </div>
                    <div class="col-4 text-right">${{ Number(itemTabletded.tDed).toLocaleString("es") }}</div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <!-- <template #modal-footer> -->
        <div class="w-100 text-right">
          <button type="submit" class="mx-1 btn px-4 py-2 btn-primary">Agregar</button>
          <button
            type="button"
            class="mx-1 btn px-4 py-2 btn-secondary"
            @click="
              nuevoItem = {};
              $bvModal.hide('my-modalDeducciones');
            "
          >
            Cerrar
          </button>
        </div>
        <!-- </template> -->
      </form>
    </b-modal>
    <!-- modal lista items empleado -->
    <!-- :title="usuarioSeleccionado != null ? 'Liquidación de ' + usuarioSeleccionado.persona.nombres + ' ' + usuarioSeleccionado.persona.apellidos : ''" -->
    <b-modal :title="'Liquidación'" id="miModalDetalle" body-bg-variant="dark" header-bg-variant="dark" footer-bg-variant="dark" size="xl" hide-footer>
      <div class="row px-5">
        <div class="col-md-12">
          <!-- {{nominaLiquidacionPersonaDetalle}} -->
          <div class="m-4 table-responsive" style="height: 50vh; overflow: scroll">
            <table class="table">
              <thead>
                <tr>
                  <!-- <th scope="col">conceptoId</th> -->
                  <th scope="col">Fecha inicio</th>
                  <th scope="col">Fecha fin</th>
                  <th scope="col">Concepto</th>
                  <th scope="col">base liquida</th>
                  <th scope="col">porcentaje trabajador</th>
                  <th scope="col">valor trabajador</th>
                  <th scope="col">porcentaje empleador</th>
                  <th scope="col">valor empleador</th>
                  <th scope="col">Devengado</th>
                  <th scope="col">Deducción</th>
                  <!-- <th scope="col">Opciones</th> -->
                </tr>
              </thead>
              <tbody v-for="itemTable of nominaLiquidacionPersonaDetalle" :key="itemTable.id">
                <tr>
                  <!-- <th scope="row">{{ itemTable.conceptoId }}</th> -->
                  <td>{{ itemTable.fechaInicio != null ? $moment(itemTable.fechaInicio).format("DD-MM-YYYY HH:mm") : "--" }}</td>
                  <td>{{ itemTable.fechaFin != null ? $moment(itemTable.fechaFin).format("DD-MM-YYYY HH:mm") : "--" }}</td>

                  <td>{{ itemTable.concepto }}</td>
                  <td>
                    {{
                      Number(itemTable.baseLiquida).toLocaleString("es-CO", {
                        style: "currency",
                        currency: "COP",
                      })
                    }}
                  </td>
                  <td>
                    {{ Number(itemTable.porcentajeTrabajador).toLocaleString("es") }}
                  </td>
                  <td>
                    {{
                      Number(itemTable.valorTrabajador).toLocaleString("es-CO", {
                        style: "currency",
                        currency: "COP",
                      })
                    }}
                  </td>
                  <td>
                    {{ Number(itemTable.porcentajeEmpleador).toLocaleString("es") }}
                  </td>
                  <td>
                    {{
                      Number(itemTable.valorEmpleador).toLocaleString("es-CO", {
                        style: "currency",
                        currency: "COP",
                      })
                    }}
                  </td>
                  <td>
                    {{
                      itemTable.tDev
                        ? Number(itemTable.tDev).toLocaleString("es-CO", {
                            style: "currency",
                            currency: "COP",
                          })
                        : 0
                    }}
                  </td>
                  <td>
                    {{
                      itemTable.tDed
                        ? Number(itemTable.tDed).toLocaleString("es-CO", {
                            style: "currency",
                            currency: "COP",
                          })
                        : 0
                    }}
                  </td>
                  <td>
                    <!-- <i
                      v-if="!['Salario', 'Auxilio de transporte', , 'Salud', 'Fondo de pensión'].includes(itemTable.concepto)"
                      @click="eliminarItem(itemTable)"
                      class="fas fa-solid fa-trash px-1 text-danger"
                    ></i> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="row mb-3">
            <div class="col 6">
              Devengados:
              {{
                nominaLiquidacionPersonaDetalle
                  .reduce((prev, curr) => prev + Number(curr.tDev), 0)
                  .toLocaleString("es-CO", {
                    style: "currency",
                    currency: "COP",
                  })
              }}
            </div>
            <div class="col 6">
              Deducciones:
              {{
                nominaLiquidacionPersonaDetalle
                  .reduce((prev, curr) => prev + Number(curr.tDed), 0)
                  .toLocaleString("es-CO", {
                    style: "currency",
                    currency: "COP",
                  })
              }}
            </div>
            <div class="col 6">
              Total:
              {{
                (
                  nominaLiquidacionPersonaDetalle.reduce((prev, curr) => prev + Number(curr.tDev), 0) -
                  nominaLiquidacionPersonaDetalle.reduce((prev, curr) => prev + Number(curr.tDed), 0)
                ).toLocaleString("es-CO", {
                  style: "currency",
                  currency: "COP",
                })
              }}
            </div>
          </div>
          <div class="text-right px-5 pb-4">
            <!-- <button @click.prevent="guardarDataUsuario()" type="button" class="mx-1 btn px-4 py-2 btn-primary">Guardar</button>
              <button class="mx-1 btn px-4 py-2 btn-secondary" @click="$router.push('/helex/numeraciones')">Cancelar</button> -->
          </div>
        </div>
      </div>
      <!-- <template #modal-footer> -->
      <div class="w-100 text-right">
        <!-- <button :hidden="item.tipoLiquidacion == 1 || item.tipoLiquidacion == 2" class="mx-1 btn px-4 py-2 btn-primary" @click="$bvModal.show('my-modalDevengados')">
          Devengados
        </button>
        <button :hidden="item.tipoLiquidacion == 1 || item.tipoLiquidacion == 2" class="mx-1 btn px-4 py-2 btn-secondary" @click="$bvModal.show('my-modalDeducciones')">
          Deducciones
        </button> -->
        <button
          class="mx-1 btn px-4 py-2 btn-success"
          @click="
            nuevoItem = {};
            $bvModal.hide('miModalDetalle');
          "
        >
          Cerrar
        </button>
      </div>
      <!-- </template> -->
    </b-modal>

    <form @submit.prevent="saveData">
      <b-row>
        <b-col sm="12">
          <card>
            <h4 class="px-4 py-4"><b>Editar nómina</b></h4>
            <template v-slot:headerTitle></template>
            <div class="row px-5 mb-4">
              <div class="col-md-6">
                <label for=" " class="form-label"> Período de Liquidación </label>
                <div>
                  <!-- :readonly="item.tipoLiquidacion == 1 || item.tipoLiquidacion == 2" -->
                  <label for=" " class="form-label">Fecha inicio</label>
                  <input readonly required v-model="item.fechaInicio" placeholder="" type="date" class="form-control" id=" " />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mt-4">
                  <label for=" " class="form-label">Fecha fin</label>
                  <!-- :readonly="item.tipoLiquidacion == 1 || item.tipoLiquidacion == 2" -->
                  <input readonly v-model="item.fechaFin" required placeholder="" type="date" class="form-control" id=" " />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mt-4">
                  <label for=" " class="form-label">Tipo de Liquidación</label>
                  <input
                    disabled
                    :value="item.tipoLiquidacion == 1 ? 'Primer semestre' : item.tipoLiquidacion == 2 ? 'Último semestre / Navideña' : 'Nómina'"
                    required
                    placeholder=""
                    type="text"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mt-4">
                  <label for=" " class="form-label">Estado</label>
                  <input disabled :value="item.estado == 1 ? 'Liquidado' : ''" required placeholder="" type="text" class="form-control" id=" " />
                </div>
              </div>
            </div>
          </card>

          <!-- <template v-slot:headerTitle> </template> -->
          <div class="px-5">
            <div class="row">
              <div class="col-12">
                <CardTable :data="dataCards" />
              </div>
              <!-- <div class="col-12 col-md">
                  <BtnAddCardTable text="Abrir Ticket" url="AgregarTickets" />
                </div> -->
            </div>
          </div>

          <!-- empleados -->
          <card>
            <!-- <h4 class="px-4 py-4"><b>Empleados/as</b></h4>
            <p class="px-4">Gestiona la información de tus empleados/as que vas a tener en cuenta para liquidar la nómina de este período.</p> -->
            <div class="px-5 py-3 pb-1">
              <RouterLink
                v-if="!liquidacion?.idSeguimientoAportesEnLinea && liquidacion"
                :to="{
                  name: 'LiquidadorNomina',
                  params: { liquidacionId: $route.params.id },
                }"
              >
                <vs-button  >Liquidar con Aportes en Linea</vs-button>
              </RouterLink>
              <div v-else>
                <div v-show="messageEstados" class="bg-primary text-white p-3 rounded">
                  <i v-show="/^[0-9]+$/.test(messageEstados.substring())" class="fas fa-spinner fa-spin text-warning pr-2"></i>
                  {{messageEstados}}
                </div>           
              </div> 
            </div>

            <template v-slot:headerTitle></template>
            <div class="row px-5 mb-4 mt-4">
              <div class="table-responsive px-4 mt-3" v-if="listaLiquidacionPersona.length > 0">
                <table id="datatable_empleados" class="table table-bordered table-responsive-md table-striped text-center">
                  <thead>
                    <tr>
                      <th>Liquidar</th>
                      <th>Nombre</th>
                      <th>Identificación</th>
                      <th>Salario</th>
                      <th>Pagos adicionales</th>
                      <th>Deducciones</th>
                      <th>Total a pagar</th>
                      <th>Opciones</th>
                      <th>Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in listaLiquidacionPersona" :key="item.dataId">
                      <td>
                        {{ item.liquidado == true ? "Liquidado" : "" }}
                        <b-form-checkbox
                          v-if="item.liquidado == null || item.liquidado != true"
                          @change="cambiarEstadoPersona(item, $event)"
                          :id="'checkbox-1' + item.persona.id"
                          v-model="item.estado"
                          :name="'checkbox-1' + item.persona.id"
                          value="0"
                          unchecked-value="-1"
                        >
                        </b-form-checkbox>
                      </td>
                      <td>
                        {{
                          item.persona.nombres +
                          " " +
                          (item.persona.segundoNombre != null ? item.persona.segundoNombre : "") +
                          " " +
                          item.persona.apellidos +
                          " " +
                          (item.persona.segundoApellido != null ? item.persona.segundoApellido : "")
                        }}
                      </td>
                      <td>{{ item.persona.numeroDocumento }}</td>
                      <td>
                        {{
                          item.persona.salario != null
                            ? item.persona.salario.toLocaleString("es-CO", {
                                style: "currency",
                                currency: "COP",
                              })
                            : (0).toLocaleString("es-CO", {
                                style: "currency",
                                currency: "COP",
                              })
                        }}
                      </td>
                      <td>
                        {{
                          item.totalIngresosAdicionales.toLocaleString("es-CO", {
                            style: "currency",
                            currency: "COP",
                          })
                        }}
                      </td>
                      <td>
                        {{
                          item.totalDeducciones.toLocaleString("es-CO", {
                            style: "currency",
                            currency: "COP",
                          })
                        }}
                      </td>
                      <td>
                        {{
                          item.totalPago.toLocaleString("es-CO", {
                            style: "currency",
                            currency: "COP",
                          })
                        }}
                      </td>
                      <td>
                        <span class="d-flex justify-between" v-if="item.estado != -1">
                          <!-- <i
                            @click="
                              $bvModal.show('my-modalDevengados');
                              usuarioSeleccionado = item;
                            "
                            class="fas fa-solid fa-plus px-1 text-success mr-2"
                          ></i>
                          <i
                            @click="
                              $bvModal.show('my-modalDeducciones');
                              usuarioSeleccionado = item;
                            "
                            class="fas fa-solid fa-minus px-1 text-red mr-2"
                          ></i> -->
                          <!-- <i @click="$router.push('departamentos_editar/' + item.id)" class="fas fa-solid fa-pen px-1 text-success mr-2"></i> -->
                          <i @click="modalDetalle(item)" class="fas fa-solid fa-eye px-1 text-success pointer"></i>
                          <i @click="$router.push('/helex/liquidacioneditardetalle/' + id + '/' + item.persona.id)" class="fas fa-solid fa-file px-1 text-success pointer"></i>                          

                          <div>
                            <i v-if="item.estadoAportes == 'Aprobado'" @click="dispatchModalLiquidacionAjuste({ liquidacionId: id, liquidacionPersonaId: item.id})" class="fas fa-solid fa-cog px-1 pointer text-warning pointer"></i>
                            <i v-else class="fas fa-solid fa-cog px-1" ></i>
                          </div>
                        </span>
                      </td>
                      <td>
                        <span @click="showConsultaLiquidacionUnica({ liquidacionId: id, liquidacionPersonaId: item.id})" >
                          <i v-if="item.estadoAportes == 'Aprobado'" class="fas fa-solid fa-check px-1 text-success pointer"></i>
                          <i v-else-if="item.estadoAportes == 'Rechazado'" class="fas fa-solid fa-exclamation-triangle px-1 text-danger pointer"></i>
                          <i v-else-if="item.estadoAportes == null" class="fas fa-solid fa-minus px-1" style="color: gray"></i>
                          <i v-else class="fas fa-solid fa-clock px-1 text-warning pointer" ></i>
                        </span>

                        <!-- <template v-if="estadoRespuestaAportes.state == 'parcial' || estadoRespuestaAportes.state == 'success'">
                          <span v-if="evalLiquidacionPersona( item ) == 'success'" @click="showConsultaLiquidacionUnica({ liquidacionId: id, liquidacionPersonaId: item.id})">
                            <i class="fas fa-solid fa-check px-1 text-success pointer"></i>
                          </span>
                          <span v-else-if="evalLiquidacionPersona( item ) == 'edited'" @click="showConsultaLiquidacionUnica({ liquidacionId: id, liquidacionPersonaId: item.id})">
                            <i class="fas fa-solid fa-check px-1 text-warning pointer"></i>
                          </span>
                          <span v-else >
                            <i class="fas fa-solid fa-exclamation-triangle px-1 text-danger pointer"></i>
                          </span>
                        </template>  -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </card>
        </b-col>
      </b-row>
      <!--<div class="text-right px-5 pb-4">
        <button type="submit" class="mx-1 btn px-4 py-2 btn-primary">Guardar</button>
        <button class="mx-1 btn px-4 py-2 btn-secondary" @click="$router.push('/helex/numeraciones')">Cancelar</button>
      </div>-->
    </form>
  </b-container>
</template>
<script>
import _ from "lodash";
import { core } from "../../../../config/pluginInit";
import CardTable from "../../../../components/cardTable/CardTableV2.vue";
const Swal = require("sweetalert2");

export default {
  components: {
    CardTable,
    NominaAjustePersona: () => import("@/views/Helexium/Nomina/NominaAjustePersona/NominaAjustePersona"),
    NominaConsultaEstados: () => import("@/views/Helexium/Nomina/NominaAjustePersona/NominaConsultaEstados"),
  },
  name: "DataTable",
  data() {
    return {
      messageEstados: '',
      dataParaLiquidacionAjuste: undefined,
      dataConsultaLiquidacionTrabajador: undefined,
      liquidacion: undefined,
      consultaNominaAportes: undefined,
      usuario: { rol: { nivel: 0 } },
      id: "",
      selected: null,
      item: {
        fechaInicio: null, //this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),
        fechaFin: null, //this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),
        CantidadEmpleados: 0.0,
        TotalSalarios: 0,
        TotalPagosAdicionales: 0, ///<-----
        TotalDeducciones: 0,
        TotalCostos: 0,
        TotalAPagar: 0,
        EmpresaId: "",
        SucursalId: "",
        Descripcion: "",
        Estado: 1,
        persona: { nombres: "", apellidos: "" },
      },
      ListEmpresas: [],
      ListSucursales: [],
      listaItems: [],
      listaEmpleados: [],
      nominaConfiguracion: {},
      listaDeducciones: [],
      listaDevengados: [],
      listaLiquidacionPersona: [],
      listaLiquidacionPersonaDetalle: [],
      nominaLiquidacionPersonaDetalle: [],
      nuevoItem: {},
      usuarioSeleccionado: null,
      porcenta: 0.75,
      porcenta2: 1.1,
      porcenta3: 1,
      valporcenta: 1,
      valporcenta2: 1,
      valporcenta3: 1,
      dataCards: [
        {
          title: "Empleados",
          value: () => this.empleadosActivos,
          iconName: "users",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
        },

        {
          title: "Salarios",
          // value: () => this.listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.salario), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" }),
          value: () => this.salarioEmpleadosActivos,

          iconName: "money-bill-1",
          color: "rgba(71, 136, 255, 1)",
          tipo: "shield",
          animated: "bounce",
        },
        {
          title: "Pagos adicionales",
          // value: () =>
          //   this.listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.totalIngresosAdicionales), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" }),
          value: () => this.pagosAdicionales,
          iconName: "hand-point-up",
          color: "rgba(71,136,255,1)",
          tipo: "shield",
          animated: "bounce",
        },
        {
          title: "Deducciones",
          // value: () => this.listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.totalDeducciones), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" }),
          value: () => this.deducciones,
          iconName: "hand-point-down",
          color: "rgba(223, 18, 46, 1)",
          tipo: "shield",
          animated: "bounce",
        },
        {
          title: "Total a pagar",
          // value: () => this.listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.totalPago), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" }),
          value: () => this.totalAPagar,
          iconName: "hand-holding-dollar",
          color: "rgba(71, 136, 255, 1)",
          tipo: "shield",
          animated: "bounce",
        },
        // {
        //   title: "Total Costo",
        //   value: () =>
        //   this.listaItems.filter(
        //       (tick) => tick.prioridad == 1
        //     ).length,
        //   iconName: "hand-point-up",
        //   color: "rgba(71, 136, 255, 1)",
        //   tipo: "shield",
        //   animated: "bounce",
        //   onActivate: () => {
        //     this.dataTableInstance.search("Baja").draw();
        //     console.log("Activated");
        //   },
        //   onDeactivate: () => {
        //     this.dataTableInstance.search("").draw();
        //     console.log("Deactivate");
        //   },
        // },
      ],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.item.EmpresaId = this.usuario.empresa.id;

    this.id = this.$route.params.id;
    await this.getData();
    await this.getEmpleados();
    await this.getNominaConfiguracion();
    await this.getDeducciones();
    await this.getDevengados();
    // await this.getEmpresas();
    // await this.getSucursalEmpresa();
    core.index();
    window.$("#datatable_empleados").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });

    window.$("#datatable_empleados2").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
    this.$isLoading(false);
  },
  computed: {
    estadoRespuestaAportes() {
      if( !this.consultaNominaAportes && (this.liquidacion?.idSeguimientoAportesEnLinea && this.liquidacion) ) {
        // existe liquidacion y se esta consultando el estado liquidador por aportes
        return { state: "waiting", message: "Consultando estado de la liquidacion"}
      } else if( this.consultaNominaAportes?.estado == "En validación") {
        return { state: "waitAportes", message: this.consultaNominaAportes?.descripcion }
      } else if ( 
        this.consultaNominaAportes?.estado == "Rechazada" &&
        this.consultaNominaAportes?.descripcion == "Las nóminas ingresadas fueron previamente aprobadas."
      ) {
        return { state: "waitAportes", message: this.consultaNominaAportes?.descripcion }
      } else if (
        this.consultaNominaAportes?.estado == "Rechazada" &&
        this.consultaNominaAportes?.descripcion == "Todos los documentos de la transmisión han sido rechazados."
      ) {
        //! Toma de acciones
        return { state: "reject", message: this.consultaNominaAportes?.descripcion }
      } else if ( this.consultaNominaAportes?.estado == "Transmitido parcialmente") {
        return { state: "parcial", message: this.consultaNominaAportes?.descripcion }
      } else if ( this.consultaNominaAportes?.estado == "Transmitida") {
        return { state: "success", message: this.consultaNominaAportes?.descripcion }
      } 

      return { state: "", message: ""}
    },  

    empleadosActivos() {
      return this.listaLiquidacionPersona.filter((x) => x.estado == 0).length;
    },
    salarioEmpleadosActivos() {
      let empleados = this.listaLiquidacionPersona.filter((x) => x.estado == 0);

      return empleados.reduce((prev, curr) => prev + Number(curr.salario), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" });
    },
    pagosAdicionales() {
      let empleados = this.listaLiquidacionPersona.filter((x) => x.estado == 0);
      return empleados.reduce((prev, curr) => prev + Number(curr.totalIngresosAdicionales), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" });
    },
    deducciones() {
      let empleados = this.listaLiquidacionPersona.filter((x) => x.estado == 0);
      return empleados.reduce((prev, curr) => prev + Number(curr.totalDeducciones), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" });
    },
    totalAPagar() {
      let empleados = this.listaLiquidacionPersona.filter((x) => x.estado == 0);
      return empleados.reduce((prev, curr) => prev + Number(curr.totalPago), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" });
    },
  },
  methods: {
    validarEstadosPorBackEnd() {
      const enviadas = this.listaLiquidacionPersona
      .filter(liq => liq.estadoAportes);

      if( enviadas.length == 0 ) {
        this.messageEstados = 'No se han enviado liquidaciones a Aportes en Línea'
        return;
      }

      const aprobados = enviadas.filter(liq => liq.estadoAportes === 'Aprobado');

      if( aprobados.length == this.listaLiquidacionPersona.length ) {
        this.messageEstados = 'Todos los documentos de las liquidaciones han sido aprobados'
        return;
      }





    },

    async consultarPersonaEnAportes( body ) {
      const token = await this.$store.getters.getTokenAportes()
      const response = await fetch(`${this.$store.state.APIAportes}/NominaElectronica/ConsultaEstado`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'token': token,
          'Content-Type': 'application/json'
        },
        body: body
      })
      return await response.json()
    },

    initConsultasAutomáticasAportes()
    {
      const idsLiquidacionPersonasPendientes = this.listaLiquidacionPersona
      .filter(liq => liq.estadoAportes === 'Pendiente')
      .map(liq => liq.id);

      this.messageEstados = 'Consultando estados en la base de datos';
      if( idsLiquidacionPersonasPendientes.length == 0 ) {
        this.messageEstados = ''
        this.validarEstadosPorBackEnd()
        return;
      }

      const queryString = idsLiquidacionPersonasPendientes.map(id => `liquidacionPersonaId=${id}`).join('&');
      this.$store.getters.fetchGet({ path: `LiquidadorNomina/GetCuerposConsultaByLiquidacionesPersonasIds?${queryString}`})
      .then(response => response.json())
      .then(data => {
        return new Promise((resolve) => {
          resolve(data.map(({body}) => body));
        })
      })
      .then( bodies => {

        const groupedData = _.groupBy(bodies, 'idTrack')
        const resultado = Object.keys(groupedData).map(idTrack => ({
          idTrack,
          trabajador: _.flatMap(groupedData[idTrack], 'trabajador')
        }));

        return new Promise((resolve) => {
          resolve(resultado);
        })
      })
      .then( async ( bodies ) => {
        bodies.forEach(async (body, index) => {
                    
          this.messageEstados = `${index + 1} Se ha iniciado el proceso de consulta de estados de liquidaciones en Aportes en Línea`;

          const result = await this.consultarPersonaEnAportes(JSON.stringify(body))
          
          this.messageEstados = result.descripcion
          // console.log('Consulta result', result)
        
          const responseSaveData = await this.$store.getters.fetchPut({
            path: `LiquidadorNomina/SaveResultLiquidacionPersonas/${body.idTrack}`,
            data: result
          })
          const resultSaveData = await responseSaveData.json()
        
          // console.log('Save result', resultSaveData)
          
          resultSaveData.forEach(  (item) => {
            const index = this.listaLiquidacionPersona.findIndex( liq => liq.id == item.id)
            this.listaLiquidacionPersona[index].estadoAportes = item.estadoAportes
          })
        
        })
      })
      .catch(error => {
        console.error(error)
      })
      .finally( () => {
        setTimeout(() => {
          this.messageEstados = ''
        }, 10000)
      })
    },

    dispatchModalLiquidacionAjuste({ liquidacionId, liquidacionPersonaId}){
      this.$bvModal.show('modalLiquidacionAjuste')
      this.dataParaLiquidacionAjuste = { liquidacionId, liquidacionPersonaId }
    },

    showConsultaLiquidacionUnica( { liquidacionId, liquidacionPersonaId} ) {
      console.log(liquidacionId, liquidacionPersonaId)
      this.$bvModal.show('consultaUnicaModal');
      this.dataConsultaLiquidacionTrabajador =  { liquidacionId, liquidacionPersonaId}
    },


    async getTokenAportes(APIAportes, { user, password }) {
      //Revisa el local storage si existe tkAportesEnLinea
      const tkAportesEnLinea = JSON.parse(localStorage.getItem("tkAportesEnLinea"));
      //Si existe y no ha pasado 1 hora desde que se generó el token, lo usa
      if (tkAportesEnLinea && new Date() - new Date(tkAportesEnLinea.fecha) < 3600000) {
        return tkAportesEnLinea.token;
      } else {

        try {
          const response = await fetch(`${APIAportes}/NominaElectronica/Autenticacion`, {
            method: "POST",
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user,
              password,
              ambiente: 2,
            }),
          });
  
          const { token } = await response.json();
          if(token) {
            localStorage.setItem("tkAportesEnLinea", JSON.stringify({ token, fecha: new Date() }));
          } else {
            throw new Error("No se pudo obtener el token");
          }
          return token;
        } catch  {
          return this.getTokenAportes(APIAportes, { user, password })
        }
      }
    },

    async consultaLiquidacionGeneral( liquidacion ) {
      const idTrack = liquidacion.idSeguimientoAportesEnLinea;
      const credenciales = {
        user: liquidacion.usuarioAportesEnLinea,
        password: liquidacion.contraseniaAportesEnLinea
      }

      const APIAportes = `https://nominaelectronicaapipruebas.aportesenlinea.com/Api`;
      const token = await this.getTokenAportes(APIAportes, credenciales)

      const response = await fetch(`${APIAportes}/NominaElectronica/ConsultaEstado`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'token': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({idTrack})
      })
      return await response.json()
    },

    async consultarLiquidacion( liquidacionPersona ) {
      const liquidacionPersonaId = liquidacionPersona.id;

      const responseCuerpo = await this.$store.getters.fetchGet({
        path: `LiquidadorNomina/GetCuerpoConsultaLiquidacionNominaAportesEnLinea/${liquidacionPersonaId}`,
      });
      const {body, credenciales} = await responseCuerpo.json();
      console.log(body);
      const APIAportes = `https://nominaelectronicaapipruebas.aportesenlinea.com/Api`;
      const token = await this.getTokenAportes(APIAportes, credenciales);

      // ! Importante revisar

      fetch(`${APIAportes}/NominaElectronica/ConsultaEstado`, {
        method: "POST",
        headers: {
          accept: "application/json",
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.log(response);
          return response.json();
        })
        .then((result) => {
          console.log(result);
        });
    },

    async ActualizaTotalesNominaLiquidacion() {
      await this.$store.dispatch("hl_post", {
        path: "NominaLiquidacion/ActualizaTotalesNominaLiquidacion/" + this.id,
      });
    },
    async cambiarEstadoPersona(item, e) {
      // alert(item.persona.id);
      // alert(e);
      // console.log("item", item);
      let dataLiquidaPersona = {
        Id: item.id,
        liquidacionId: item.liquidacionId,
        PersonaId: item.persona.id,
        Estado: e,
        Salario: Number(item.persona.salario),
        totalIngresosAdicionales: Number(item.totalIngresosAdicionales),
        TotalDeducciones: Number(item.totalDeducciones),
        TotalPago: Number(item.totalPago),
        MetodoPago: Number(item.MetodoPago),
        DiasLiquidados: Number(item.diasLiquidados),
      };
      console.log(dataLiquidaPersona);
      await this.$store.dispatch("hl_post", {
        path: "NominaLiquidacion/NominaLiquidacionPersona",
        data: dataLiquidaPersona,
      });
      this.ActualizaTotalesNominaLiquidacion();
    },
    async liquidarNomina() {
      this.$isLoading(true);
      //crear lista con empleados activos

      let empleadosALiquidar = this.listaLiquidacionPersona.filter((x) => x.estado == 0).map((y) => y);
      console.log("lista de empleados a liquidar", empleadosALiquidar);
      let path = "NominaLiquidacion/LiquidarPersonas/";
      await this.$store.dispatch("hl_post", {
        path: path,
        data: empleadosALiquidar,
      });
      // this.$isLoading(false);
      // await this.getData();
      // await this.getEmpleados();
      // this.ActualizaTotalesNominaLiquidacion();
      // this.$forceUpdate();
      this.$isLoading(false);
      Swal.fire("Listo!", "Liquidación procesada correctamente!.", "success");
    },
    async recalculaLiquida() {
      this.$isLoading(true);

      let path =
        "NominaLiquidacion/CrearNominaLiquidacionAutoEmpresa/" + this.item.tipoLiquidacion + "/" + this.item.empresaId + "/" + this.item.fechaInicio + "/" + this.item.fechaFin;
      await this.$store.dispatch("hl_post", {
        path: path,
      });
      this.$isLoading(false);
      await this.getData();
      await this.getEmpleados();
      this.ActualizaTotalesNominaLiquidacion();
      this.$forceUpdate();
    },
    async actualizaTotalesLiquida() {
      let salarios = this.listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.salario), 0);
      console.log("salarios", salarios);

      let empleados = this.listaLiquidacionPersona.length;
      console.log(empleados);

      let pagosAdicionales = this.listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.totalIngresosAdicionales), 0);
      console.log(pagosAdicionales);

      let deducciones = this.listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.totalDeducciones), 0);
      console.log(deducciones);

      let totalAPagar = this.listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.totalPago), 0);
      console.log(totalAPagar);

      let data = {
        Id: this.id,
        TotalSalarios: salarios,
        TotalPagosAdicionales: pagosAdicionales,
        TotalDeducciones: deducciones,
        TotalAPagar: totalAPagar,
        CantidadEmpleados: empleados,
      };
      await this.$store.dispatch("hl_post", {
        path: "NominaLiquidacion/NominaLiquidacion",
        data: data,
      });
    },
    async showModalEmpleados() {
      for await (let itemp of this.listaEmpleados) {
        let idt = this.listaLiquidacionPersona.findIndex((x) => x.persona.id == itemp.id);
        console.log(idt);
        if (idt > -1) {
          itemp.show = true;
        } else {
          itemp.show = false;
        }
      }
      this.$bvModal.show("my-modal");
      this.$forceUpdate();
    },
    async recargandoDatos() {
      this.$isLoading(true);
      await this.getData();
      await this.getEmpleados();
      await this.getNominaConfiguracion();
      //await this.getDeducciones();
      //await this.getDevengados();
      // await this.getEmpresas();
      // await this.getSucursalEmpresa();
      // core.index();
      // window.$("#datatable_empleados").DataTable({
      //   language: {
      //     url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      //   },
      // });

      // window.$("#datatable_empleados2").DataTable({
      //   language: {
      //     url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      //   },
      // });
      this.$isLoading(false);
    },

    deleteRow(id) {
      Swal.fire({
        title: "¿Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            this.$isLoading(true);
            //let loader = this.$loading.show();
            let res = await this.$store.dispatch("hl_delete", {
              path: "NominaLiquidacion/DelNominaLiquidacionPersona/" + id,
            });
            if (res) {
              await this.recargandoDatos();
              await this.actualizaTotalesLiquida();

              this.$isLoading(false);
              Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
            } else {
              this.$isLoading(false);
            }
          } catch (error) {
            this.$isLoading(false);
            console.log("error", error);
          }
        }
      });
    },
    async dataModalDetalle(it) {
      for await (let itemDetalle of it.nominaLiquidacionPersonaDetalle) {
        if (itemDetalle.tipoNovedadId == 2) {
          console.log("lista dev", itemDetalle.conceptoId);
          console.log("lista ", this.listaDevengados);

          let filtroDev = this.listaDevengados.filter((x) => x.id == itemDetalle.conceptoId);

          console.log("lista dev", filtroDev);

          itemDetalle.concepto = filtroDev[0].descripcion;
        }
        if (itemDetalle.tipoNovedadId == 1) {
          let filtroDed = this.listaDeducciones.filter((x) => x.id == itemDetalle.conceptoId);
          itemDetalle.concepto = filtroDed[0].descripcion;
        }
      }
      this.nominaLiquidacionPersonaDetalle = it.nominaLiquidacionPersonaDetalle;
      console.log(this.nominaLiquidacionPersonaDetalle);
      this.usuarioSeleccionado = it;
      console.log("usuario seleccionado ", it);
    },
    async modalDetalle(it) {
      try {
        await this.dataModalDetalle(it);
        this.$bvModal.show("miModalDetalle");
      } catch (error) {
        console.log("error", error);
      }
    },
    async actualizaCalculosEmpleadoSel() {
      try {
        console.log("this.usuarioSeleccionado", this.usuarioSeleccionado);
        let id = this.listaLiquidacionPersona.findIndex((x) => x.persona.id == this.usuarioSeleccionado.persona.id);

        // this.listaLiquidacionPersona[id].totalIngresosAdicionales = (this.nominaLiquidacionPersonaDetalle.reduce((prev, curr) => prev + Number(curr.tDev), 0)-this.usuarioSeleccionado.salario);
        console.log("nominaLiquidacionPersonaDetalle.salario", this.usuarioSeleccionado.persona.salario);

        this.listaLiquidacionPersona[id].totalIngresosAdicionales = this.nominaLiquidacionPersonaDetalle.reduce((prev, curr) => {
          console.log("currr", curr);
          //prev + Number(curr.tDev)
          return curr.concepto != "Salario" ? Number(prev) + Number(curr.tDev) : prev;
        }, 0);

        this.listaLiquidacionPersona[id].totalDeducciones = this.nominaLiquidacionPersonaDetalle.reduce((prev, curr) => prev + Number(curr.tDed), 0);
        this.listaLiquidacionPersona[id].totalPago =
          this.nominaLiquidacionPersonaDetalle.reduce((prev, curr) => prev + Number(curr.tDev), 0) -
          this.nominaLiquidacionPersonaDetalle.reduce((prev, curr) => prev + Number(curr.tDed), 0);
        //       let dataPersona = {
        //   dataId: this.listaLiquidacionPersona + 1,
        //   id: null,
        //   liquidacionId: null,
        //   estado: 0,
        //   totalIngresosAdicionales: 0,
        //   totalDeducciones: 0,
        //   totalPago: 0,
        //   metodoPago: 0,
        //   diasLiquidados: 0,
        //   persona: item,
        //   nominaLiquidacionPersonaDetalle: [],
        // };
      } catch (error) {
        console.log("error ln1017", error);
      }
    },
    async guardarDataUsuario() {
      try {
        this.$isLoading(true);
        console.log("this.listaLiquidacionPersona ", this.listaLiquidacionPersona);
        let id = this.listaLiquidacionPersona.findIndex((x) => x.persona.id == this.usuarioSeleccionado.persona.id);
        console.log("id--->", id);
        this.listaLiquidacionPersona[id].nominaLiquidacionPersonaDetalle = this.nominaLiquidacionPersonaDetalle;
        console.log("this.listaLiquidacionPersona---> ", this.listaLiquidacionPersona[id]);
        if (this.listaLiquidacionPersona[id].nominaLiquidacionPersonaDetalle.length > 0) {
          console.log("this.listaLiquidacionPersona000---> ", this.listaLiquidacionPersona[id].nominaLiquidacionPersonaDetalle);
          let dataDetalle = this.listaLiquidacionPersona[id].nominaLiquidacionPersonaDetalle;
          for await (let itnlpd of dataDetalle) {
            console.log("***itnlpd ", itnlpd);

            let dataLiquidaPersonaDetalle = {
              Id: itnlpd.id,
              nominaLiquidacionPersonaId: this.listaLiquidacionPersona[id].id,
              conceptoId: itnlpd.conceptoId,
              BaseLiquida: itnlpd.baseLiquida,
              porcentajeTrabajador: itnlpd.porcentajeTrabajador,
              valorTrabajador: itnlpd.valorTrabajador,
              porcentajeEmpleador: itnlpd.porcentajeEmpleador,
              valorEmpleador: itnlpd.valorEmpleador,
              Dias: itnlpd.dias,
              Valor: itnlpd.valor,
              TDev: itnlpd.tDev,
              TDed: itnlpd.tDed,
            };
            console.log("itnlpd 2 ", dataLiquidaPersonaDetalle);

            let res = await this.$store.dispatch("hl_post", {
              path: "NominaLiquidacion/NominaLiquidacionPersonaDetalle",
              data: dataLiquidaPersonaDetalle,
            });
            itnlpd.id == res;
            console.log("res---->*/*", res);
          }
        }
        this.$isLoading(false);
        await this.getData();
        await this.dataModalDetalle(this.listaLiquidacionPersona[id]);
        this.actualizaCalculosEmpleadoSel();
        await this.saveData();

        Swal.fire("Muy bien!", "Datos almacenados correctamente.", "success");
      } catch (error) {
        this.$isLoading(false);
        Swal.fire("Error!", "Intentalo nuevamente.", "error");
        console.log("error", error);
      }
    },
    eliminarItem(item) {
      try {
        console.log(item);
        Swal.fire({
          title: "¿Seguro eliminar item?",
          text: "Después de eliminarlo no podrás recuperarlo!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si!",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              this.$isLoading(true);
              //let loader = this.$loading.show();

              let res = await this.$store.dispatch("hl_delete", {
                path: "NominaLiquidacion/DelNominaLiquidacionPersonaItem/",
                data: item,
              });
              // if (res) {
              console.log(res);
              if (res.status == 400) {
                this.$isLoading(false);
                Swal.fire("Error!", "Intentalo nuevamente.", "error");
              } else {
                // await this.recargandoDatos();
                console.log(item);
                console.log("detalle", this.nominaLiquidacionPersonaDetalle);
                this.nominaLiquidacionPersonaDetalle.splice(
                  this.nominaLiquidacionPersonaDetalle.findIndex((x) => x.concepto == item.concepto),
                  1
                );
                this.actualizaCalculosEmpleadoSel();
                await this.saveData();
                this.$forceUpdate();
                this.$isLoading(false);
                Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
              }
            } catch (error) {
              this.$isLoading(false);
              Swal.fire("Error!", "Intentalo nuevamente.", "error");
              console.log("error", error);
            }
          }
        });
      } catch (error) {
        console.log("error", error);
      }
    },
    async agregarNuevoItem() {
      try {
        //this.listaLiquidacionPersona
        if (this.nuevoItem.tipo == "devengado") {
          // //DEVENGADOS

          let itDevengados = this.listaDevengados.find((x) => x.listaDevengado.id == this.nuevoItem.itemId);
          console.log("this.nuevoItem ", this.nuevoItem);
          console.log("item itdevengado ", itDevengados);
          switch (itDevengados.listaDevengado.descripcion) {
            case "Cesantías":
              if (this.usuarioSeleccionado.persona.salario == this.nominaConfiguracion.salarioMinimo) {
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDevengados.listaDevengado.descripcion,
                  conceptoId: itDevengados.listaDevengado.id,
                  baseLiquida: this.usuarioSeleccionado.persona.salario + this.nominaConfiguracion.auxilioDeTransporte,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: this.nominaConfiguracion.cesantias,
                  valorEmpleador: (((this.usuarioSeleccionado.persona.salario + this.nominaConfiguracion.auxilioDeTransporte) * this.nominaConfiguracion.cesantias) / 100).toFixed(
                    2
                  ),
                  dias: this.nuevoItem.dias,
                  valor: this.nuevoItem.valor,
                  tDev: this.nuevoItem.total,
                  tDed: 0,
                });
                this.nuevoItem = {};
              }
              break;
            case "Prima salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.usuarioSeleccionado.persona.salario + this.nominaConfiguracion.auxilioDeTransporte,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: this.nominaConfiguracion.primaDeServicios,
                valorEmpleador: (
                  ((this.usuarioSeleccionado.persona.salario + this.nominaConfiguracion.auxilioDeTransporte) * this.nominaConfiguracion.primaDeServicios) /
                  100
                ).toFixed(2),
                dias: this.nuevoItem.dias,
                valor: this.nuevoItem.valor,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Viáticos salariales":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.valor,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.nuevoItem.valor,
                tDev: this.nuevoItem.valor,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Viáticos no salariales":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.valor,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.nuevoItem.valor,
                tDev: this.nuevoItem.valor,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Horas extras diurnas":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.nuevoItem.total,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Horas extras nocturnas":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.nuevoItem.total,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Horas extras dominicales y festivas":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.nuevoItem.total,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Horas extras nocturnas dominicales y festivas":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.nuevoItem.total,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Horas recargo nocturno":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.nuevoItem.total,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Horas recargo dominical y festiva":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.nuevoItem.total,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Hora recargo nocturno dominical y festivos":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.nuevoItem.total,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Vacaciones disfrutadas":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: this.nuevoItem.valor,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Vacaciones compensadas no disfrutadas":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: this.nuevoItem.valor,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Prima no salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.nuevoItem.valor,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Incapacidad común":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Incapacidad profesional":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Incapacidad laboral":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Licencia de maternidad o paternidad":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Licencia remunerada":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Licencia no remunerada":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Bonificación salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Bonificación no salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Auxilio salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Auxilio no salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Huelga legal":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Otro concepto salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Otro concepto no salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Compensación ordinaria":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Compensación extraordinaria":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Bono de alimentación salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Bono de alimentación no salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Otros bonos salariales":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Otros bonos no salariales":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Comisión":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Pago a terceros":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Anticipo":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Dotación":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Apoyo sostenimiento":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Teletrabajo":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Bonificación por retiro":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Indemnización":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Reintegro":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
          }
        } else {
          console.log("nuevoitem in agregarnuevoitem", this.nuevoItem);
          if (this.nuevoItem.tipo == "deduccion") {
            console.log("es deduccion.....");
            let itDeduccion = this.listaDeducciones.find((x) => x.listaDeduccion.id == this.nuevoItem.itemId);
            console.log("this.nuevoItem ", this.nuevoItem);
            console.log("item itdevengado ", itDeduccion);
            switch (itDeduccion.listaDeduccion.descripcion) {
              case "Sindicato":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Sanción pública":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Sanción privada":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Libranza":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Pago a terceros":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Anticipos":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Otras deducciones":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Pensión voluntaria":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Retención en la fuente":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "AFC":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Cooperativa":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Embargo fiscal":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Plan complementario":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Educación":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Reintegro":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Deuda":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
            }
          }
        }
        this.actualizaCalculosEmpleadoSel();
        /////////////
        await this.guardarDataUsuario();
        await this.actualizaTotalesLiquida();

        ////////////
        this.$forceUpdate();
      } catch (error) {
        console.log("error", error);
      }
    },
    obtenerDescripcionItem(tipo, itemId) {
      if (tipo == "devengado") {
        let res = this.listaDevengados.find((x) => x.listaDevengadoId == itemId);
        console.log("----", tipo, itemId, res);
        console.log("--- this.listaDevengados -", this.listaDevengados);
        return res.listaDevengado.descripcion;
      } else {
        let res = this.listaDeducciones.find((x) => x.listaDeduccionId == itemId);
        console.log("----", tipo, itemId, res);
        console.log("--- this.listaDeducciones -", this.listaDeducciones);
        return res.listaDeduccion.descripcion;
      }
    },
    calculoNuevoItem() {
      setTimeout(() => {
        if (["Cesantías", "Prima salarial"].includes(this.nuevoItem.descripcion)) {
          this.nuevoItem.total = (this.nuevoItem.dias * this.nuevoItem.valor) / 360;
          this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
          this.$forceUpdate();
        } else {
          if (["Viáticos salariales", "Viáticos no salariales"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor;
            this.$forceUpdate();
          }
          if (["Horas extras diurnas"].includes(this.nuevoItem.descripcion)) {
            let total = 0;
            let horasMes = 240;
            let precioHora = this.usuarioSeleccionado.persona.salario / horasMes;
            let porcentajeAdicional = precioHora * 0.25;
            total = (porcentajeAdicional + precioHora) * this.nuevoItem.valor;

            this.nuevoItem.total = total.toFixed(2);
            this.$forceUpdate();
          }
          if (["Horas extras nocturnas"].includes(this.nuevoItem.descripcion)) {
            let total = 0;
            let horasMes = 240;
            let precioHora = this.usuarioSeleccionado.persona.salario / horasMes;
            let porcentajeAdicional = precioHora * 0.75;
            total = (porcentajeAdicional + precioHora) * this.nuevoItem.valor;

            this.nuevoItem.total = total.toFixed(2);
            this.$forceUpdate();
          }
          if (["Horas extras dominicales y festivas"].includes(this.nuevoItem.descripcion)) {
            let total = 0;
            let horasMes = 240;
            let precioHora = this.usuarioSeleccionado.persona.salario / horasMes;
            let porcentajeAdicional = precioHora * 1;
            total = (porcentajeAdicional + precioHora) * this.nuevoItem.valor;

            this.nuevoItem.total = total.toFixed(2);
            this.$forceUpdate();
          }
          if (["Horas extras nocturnas dominicales y festivas"].includes(this.nuevoItem.descripcion)) {
            let total = 0;
            let horasMes = 240;
            let precioHora = this.usuarioSeleccionado.persona.salario / horasMes;
            let porcentajeAdicional = precioHora * 1.5;
            total = (porcentajeAdicional + precioHora) * this.nuevoItem.valor;

            this.nuevoItem.total = total.toFixed(2);
            this.$forceUpdate();
          }
          if (["Horas recargo nocturno"].includes(this.nuevoItem.descripcion)) {
            let total = 0;
            let horasMes = 240;
            let precioHora = this.usuarioSeleccionado.persona.salario / horasMes;
            let porcentajeAdicional = precioHora * 0.35;
            total = (porcentajeAdicional + precioHora) * this.nuevoItem.valor;

            this.nuevoItem.total = total.toFixed(2).toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Horas recargo dominical y festiva"].includes(this.nuevoItem.descripcion)) {
            let total = 0;
            let horasMes = 240;
            let precioHora = this.usuarioSeleccionado.persona.salario / horasMes;
            let porcentajeAdicional = precioHora * this.porcenta;
            total = (porcentajeAdicional + precioHora) * this.nuevoItem.valor;

            this.nuevoItem.total = total.toFixed(2).toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Hora recargo nocturno dominical y festivos"].includes(this.nuevoItem.descripcion)) {
            let total = 0;
            let horasMes = 240;
            let precioHora = this.usuarioSeleccionado.persona.salario / horasMes;
            let porcentajeAdicional = precioHora * this.porcenta2;
            total = (porcentajeAdicional + precioHora) * this.nuevoItem.valor;

            this.nuevoItem.total = total.toFixed(2).toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Vacaciones disfrutadas"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = (this.nuevoItem.dias * this.nuevoItem.valor) / 30;
            this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Vacaciones compensadas no disfrutadas"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = (this.nuevoItem.dias * this.nuevoItem.valor) / 30;
            this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Prima no salarial"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor;
            this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Incapacidad común"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = (this.usuarioSeleccionado.persona.salario / 30) * this.nuevoItem.dias * this.porcenta3;
            this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Incapacidad profesional"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = (this.usuarioSeleccionado.persona.salario / 30) * this.nuevoItem.dias;
            this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Incapacidad laboral"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = (this.usuarioSeleccionado.persona.salario / 30) * this.nuevoItem.dias;
            this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Licencia de maternidad o paternidad"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = (this.usuarioSeleccionado.persona.salario / 30) * this.nuevoItem.dias;
            this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Licencia remunerada"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = (this.usuarioSeleccionado.persona.salario / 30) * this.nuevoItem.dias;
            this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Licencia no remunerada"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = 0;
            this.$forceUpdate();
          }
          if (["Bonificación salarial"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Bonificación no salarial"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Auxilio salarial"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Auxilio no salarial"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Huelga legal"].includes(this.nuevoItem.descripcion)) {
            //falta verificar como se calcula
            this.nuevoItem.total = 0;
            this.$forceUpdate();
          }
          if (["Otro concepto salarial"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Otro concepto no salarial"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Compensación ordinaria"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Compensación extraordinaria"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Bono de alimentación salarial"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Bono de alimentación no salarial"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Otros bonos salariales"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Otros bonos no salariales"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Comisión"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Pago a terceros"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Anticipo"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Dotación"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Apoyo sostenimiento"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Teletrabajo"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Bonificación por retiro"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Indemnización"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Reintegro"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          //deducciones
          if (["Sindicato"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = (this.usuarioSeleccionado.persona.salario / 30) * (this.nuevoItem.valor * 0.01);
            this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Sanción pública"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Sanción privada"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Libranza"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Pago a terceros"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Anticipos"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Otras deducciones"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Pensión voluntaria"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Retención en la fuente"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["AFC"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Cooperativa"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Embargo fiscal"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Plan complementario"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Educación"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Reintegro"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Deuda"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
        }
        console.log("cal", this.nuevoItem.total);
      }, 1000);
    },
    async selecionarItem(e, tipo) {
      try {
        this.nuevoItem.tipo = tipo;
        this.nuevoItem.itemId = e;
        this.nuevoItem.dias = 0;
        this.nuevoItem.valor = 0;
        this.nuevoItem.total = 0;
        this.porcenta = 0.75;
        this.porcenta2 = 1.1;
        this.porcenta3 = 1;
        this.valporcenta = 1;
        this.valporcenta2 = 1;
        this.valporcenta3 = 1;
        this.nuevoItem.descripcion = this.obtenerDescripcionItem(tipo, this.nuevoItem.itemId);
        console.log("---------- nuevo item ", this.nuevoItem);
        this.$forceUpdate();
      } catch (error) {
        console.log("error", error);
      }
    },
    async getDevengados() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Devengado/GetDevengados/",
        });
        this.listaDevengados = res;
        console.log("list devengados", this.listaDevengados);
      } catch (error) {
        this.listaDevengados = {};
        console.log("err", error);
      }
    },
    async getDeducciones() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Deduccion/GetDeducciones/",
        });
        this.listaDeducciones = res;
        console.log("list DEDUCCIONES **************", this.listaDeducciones);
      } catch (error) {
        this.listaDeducciones = {};
        console.log("err", error);
      }
    },
    async getNominaConfiguracion() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "NominaConfiguracion/GetNominaConfiguracion/",
        });
        this.nominaConfiguracion = res;

        console.log("list this.nominaConfiguracion ***************************", this.nominaConfiguracion);
      } catch (error) {
        this.nominaConfiguracion = {};
        console.log("err", error);
      }
    },

    async agregarEmpleado(item) {
      try {
        console.log("Agregando empleados ", item);
        // validaciones...
        if (item.salario == null || item.salario == 0) {
          Swal.fire("Error!", "Empleado no tiene salario asignado.", "error");

          return;
        }
        this.$isLoading(true);
        this.nominaLiquidacionPersonaDetalle = [];
        this.usuarioSeleccionado = {};
        item.estado == 0 ? (item.estado = 1) : (item.estado = 0);
        //inicio creando la data
        let dataPersona = {
          dataId: this.listaLiquidacionPersona + 1,
          id: null,
          liquidacionId: null,
          estado: 0,
          totalIngresosAdicionales: 0,
          totalDeducciones: 0,
          totalPago: 0,
          metodoPago: 0,
          diasLiquidados: 0,
          persona: item,
          nominaLiquidacionPersonaDetalle: [],
        };
        console.log("datapersona ln2706", dataPersona);
        //DEVENGADOS
        for await (let itDevengados of this.listaDevengados) {
          console.log("for await devengados", itDevengados.listaDevengado.descripcion);
          if (itDevengados.listaDevengado.descripcion == "Salario") {
            console.log("for await devengados", itDevengados.listaDevengado);

            this.nominaLiquidacionPersonaDetalle.push({
              concepto: itDevengados.listaDevengado.descripcion,
              conceptoId: itDevengados.listaDevengado.id,
              baseLiquida: dataPersona.persona.salario,
              porcentajeTrabajador: 0,
              valorTrabajador: 0,
              porcentajeEmpleador: 0,
              valorEmpleador: dataPersona.persona.salario,
              dias: 0,
              valor: 0,
              tDev: dataPersona.persona.salario,
              tDed: 0,
            });
          }
          if (itDevengados.listaDevengado.descripcion == "Auxilio de transporte") {
            if (dataPersona.persona.salario == this.nominaConfiguracion.salarioMinimo) {
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nominaConfiguracion.auxilioDeTransporte,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: this.nominaConfiguracion.auxilioDeTransporte,
                dias: 0,
                valor: 0,
                tDev: this.nominaConfiguracion.auxilioDeTransporte,
                tDed: 0,
              });
            }
          }
        }
        //DEDUCCIONES
        for await (let itDeducciones of this.listaDeducciones) {
          console.log("for await deducciones", itDeducciones.listaDeduccion.descripcion);

          //2 devengados obligatorios: salario y auxilio transporte, en el caso de auxilio trans. depende de si es salario minimo
          if (itDeducciones.listaDeduccion.descripcion == "Salud") {
            this.nominaLiquidacionPersonaDetalle.push({
              concepto: itDeducciones.listaDeduccion.descripcion,
              conceptoId: itDeducciones.listaDeduccion.id,
              baseLiquida: dataPersona.persona.salario,
              porcentajeTrabajador: this.nominaConfiguracion.saludTrabajador,
              valorTrabajador: (dataPersona.persona.salario * this.nominaConfiguracion.saludTrabajador) / 100,
              porcentajeEmpleador: this.nominaConfiguracion.saludEmpleador,
              valorEmpleador: (dataPersona.persona.salario * this.nominaConfiguracion.saludEmpleador) / 100,
              dias: 0,
              valor: 0,
              tDev: 0,
              tDed: (dataPersona.persona.salario * this.nominaConfiguracion.saludEmpleador) / 100,
            });
          }
          if (itDeducciones.listaDeduccion.descripcion == "Fondo de pensión") {
            this.nominaLiquidacionPersonaDetalle.push({
              concepto: itDeducciones.listaDeduccion.descripcion,
              conceptoId: itDeducciones.listaDeduccion.id,
              baseLiquida: dataPersona.persona.salario,
              porcentajeTrabajador: this.nominaConfiguracion.pensionTrabajador,
              valorTrabajador: (dataPersona.persona.salario * this.nominaConfiguracion.pensionTrabajador) / 100,
              porcentajeEmpleador: this.nominaConfiguracion.pensionEmpleador,
              valorEmpleador: (dataPersona.persona.salario * this.nominaConfiguracion.pensionEmpleador) / 100,
              dias: 0,
              valor: 0,
              tDev: 0,
              tDed: (dataPersona.persona.salario * this.nominaConfiguracion.pensionEmpleador) / 100,
            });
          }
        }
        ////let total
        console.log("despues de for await", dataPersona);
        dataPersona.totalDeducciones = this.nominaLiquidacionPersonaDetalle.reduce((prev, curr) => prev + curr.valorTrabajador, 0);
        dataPersona.totalPago = this.nominaLiquidacionPersonaDetalle[0].baseLiquida + this.nominaLiquidacionPersonaDetalle[1].baseLiquida - dataPersona.totalDeducciones;
        dataPersona.nominaLiquidacionPersonaDetalle = this.nominaLiquidacionPersonaDetalle;
        this.listaLiquidacionPersona.push(dataPersona);
        this.usuarioSeleccionado = dataPersona;
        console.log("despues de for await", dataPersona);
        console.log("despues de despues de for await", this.usuarioSeleccionado);

        await this.actualizaCalculosEmpleadoSel();
        //guardo empleados en db
        await this.saveData();
        await this.guardarDataUsuario();
        this.$isLoading(false);
        this.$bvModal.hide("my-modal");
      } catch (error) {
        this.$isLoading(false);
        console.log("error en agregar usuario", error);
      }
    },
    async getSucursalEmpresa() {
      try {
        console.log("get empresas...");
        let res = await this.$store.dispatch("hl_get", {
          path: "Sucursal/ListSucursalEmpresa/" + this.usuario.empresa.id,
        });
        console.log("post sucursal... ", res);
        if (res.length > 0) {
          this.ListSucursales = res;
        } else {
          this.ListSucursales = [];
        }
      } catch (error) {
        this.ListSucursales = [];
        console.log("err", error);
      }
    },
    async getEmpleados() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Persona/ListPersona/" + this.usuario.empresa.id,
        });
        this.listaEmpleados = res;

        console.log("list", this.listaEmpleados);
      } catch (error) {
        this.listaEmpleados = {};
        console.log("err", error);
      }
    },
    async getEmpresas() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "Empresa/ListEmpresa/",
        });
        this.ListEmpresas = res;

        console.log("list", this.ListEmpresas);
      } catch (error) {
        this.ListEmpresas = {};
        console.log("err", error);
      }
    },
    async getData() {
      // Important: liquidacion, listaLiquidacionPersona
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "NominaLiquidacion/GetNominaLiquidacionId/" + this.id,
        });
        this.item = res;
        this.item.fechaInicio = this.$moment.tz(this.item.fechaInicio, "America/Costa_Rica").format("YYYY-MM-DD");
        this.item.fechaFin = this.$moment.tz(this.item.fechaFin, "America/Costa_Rica").format("YYYY-MM-DD");
        console.log("////////*////////////*", this.item);
        //obtenemos lista de GetNominaLiquidacionPersonas
        //let str =
        let resLiquidaPersona = await this.$store.dispatch("hl_get", {
          path: "NominaLiquidacion/GetNominaLiquidacionPersonas/" + this.id,
        });

        this.listaLiquidacionPersona = resLiquidaPersona.filter((x) => x.liquidado == true);
        console.log("this.listaLiquidacionPersona******************> ", this.listaLiquidacionPersona);
        
        
        let liquidacionId = this.id;
        const responseLiquidacion = await this.$store.getters.fetchGet({
          path: `LiquidadorNomina/GetLiquidacion/${liquidacionId}`
        })
        this.liquidacion  = await responseLiquidacion.json()
        this.initConsultasAutomáticasAportes()
      } catch (error) {
        this.item = {};
        console.log("err", error);
      }
    },

    async saveData() {
      //console.log("saving", this.listaLiquidacionPersona);
      for await (let itt of this.listaLiquidacionPersona) {
        let dataLiquidaPersona = {
          Id: itt.id,
          liquidacionId: this.id,
          PersonaId: itt.persona.id,
          Estado: 0,
          Salario: Number(itt.persona.salario),
          totalIngresosAdicionales: Number(itt.totalIngresosAdicionales),
          TotalDeducciones: Number(itt.totalDeducciones),
          TotalPago: Number(itt.totalPago),
          MetodoPago: 0,
          DiasLiquidados: 0,
        };
        console.log(dataLiquidaPersona);
        let res = await this.$store.dispatch("hl_post", {
          path: "NominaLiquidacion/NominaLiquidacionPersona",
          data: dataLiquidaPersona,
        });
        await this.actualizaTotalesLiquida();
        console.log("res", res);
        await this.getData();
      }
    },
  },
};
</script>
<style scoped>
th {
  position: sticky;
  top: 0px; /* 0px if you don't have a navbar, but something is required */
  background: #0c1a30;
}
</style>
